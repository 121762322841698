import MuiFormControl from '@mui/material/FormControl'
import MuiSelect from '@mui/material/Select'
import { styled } from '@mui/system'

const FormControl = styled(MuiFormControl)`
	& .MuiInputLabel-root.Mui-focused {
		color: ${({ theme }) => theme.palette.grey.main};
		&.Mui-error {
			color: ${({ theme }) => theme.palette.error.main};
		}
	}
	& .MuiInputBase-root {
		margin-top: var(--spacing-2);
		& .MuiSelect-select {
			min-width: 66px;
			line-height: 16px;
			min-height: inherit;
			color: ${({ theme }) => theme.palette.grey.medium};
			&[aria-expanded='true'] {
				color: ${({ theme }) => theme.palette.primary.main};
			}
			&.Mui-disabled {
				-webkit-text-fill-color: ${({ theme }) => theme.palette.grey.medium};
				background-color: ${({ theme }) => theme.palette.grey.light};
			}
			&.MuiInputBase-input.MuiOutlinedInput-input {
				padding-right: 45px;
			}
		}

		&.MuiOutlinedInput-root {
			&:hover:not(.Mui-error):not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
				border-width: 2px;
				border-color: ${({ theme }) => theme.palette.primary.main};
			}
		}
		&.selected {
			& .MuiSelect-select {
				color: ${({ theme }) => theme.palette.primary.main};
			}
			& .MuiOutlinedInput-notchedOutline {
				border-color: ${({ theme }) => theme.palette.primary.main};
			}
			&.Mui-error .MuiOutlinedInput-notchedOutline {
				border-color: ${({ theme }) => theme.palette.error.main};
			}
		}
		&.Mui-error {
			color: ${({ theme }) => theme.palette.primary.main};
		}
	}
	& .MuiSelect-icon {
		width: 20px;
		height: 20px;
		right: 0px;
		top: 2px;
		color: ${({ theme }) => theme.palette.primary.main};
		&.Mui-disabled {
			color: ${({ theme }) => theme.palette.grey.medium};
		}
	}
`

const Select = styled(MuiSelect)``

export { Select, FormControl }
